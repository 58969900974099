<template>
	<div
		class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
		id="page-login"
	>
		<div class="vx-col sm:w-1/1 xs:w-1/1 lg:w-1/1 sm:m-0 m-4" v-if="windowWidth > 600">
			<img src="@/assets/images/logo/koblatil.png" alt="Kobla" class="pr-5 w-1/3"/>
			<vx-card class="auth-card">
				<div slot="no-body" class="full-page-bg-color">
					<div class="vx-row no-gutter justify-center items-center">
						<div class="vx-col sm:w-full md:w-full lg:w-full d-theme-dark-bg">
							<div class="px-8 pt-8 login-number-tabs-container">
								<vs-row vs-type="flex" vs-justify="flex-end">
									<h4 @click="loginUser" class="mb-4 title-auth">{{ $t('Login') }} </h4>
								</vs-row>
								<div class="vx-card__title">
									<h4 class="mb-4">{{ $t('Login') }}</h4>
									<p>{{ $t('Please Login with your phone number') }}</p>
								</div>
								<login-sms-form></login-sms-form>
							</div>
						</div>
					</div>
				</div>
			</vx-card>
		</div>
		<div class="vx-col sm:w-1/1 xs:w-1/1 lg:w-1/1 sm:m-0 m-4" v-if="windowWidth < 600">
			<img src="@/assets/images/logo/koblatil.png" alt="Kobla" class="pr-5 w-1/3"/>
				<div class="auth-mobile">
					<vs-row vs-type="flex" vs-justify="flex-end">
						<h4 @click="loginUser" class="mb-4 title-auth">{{ $t('Login') }} </h4>
					</vs-row>
					<div class="vx-card__title">
						<h4 class="mb-4">{{ $t('Login') }}</h4>
						<p>{{ $t('Please Login with your phone number') }}</p>
					</div>
					<login-sms-form></login-sms-form>
				</div>
			</div>
		</div>
</template>

<script>
import LoginSmsForm from './LoginSMSForm.vue';

export default {
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  components: {
    LoginSmsForm,
  },
  methods: {
    loginUser() {
      this.$router.push('/login').catch(() => {});
    },
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
};
</script>

<style lang="scss">
.auth-card{
	min-width: 470px;
}
.auth-mobile{
	min-width: 300px;
}
.login-number-tabs-container {
	min-height: 300px;

	.con-tab {
		padding-bottom: 14px;
	}

	.con-slot-tabs {
		margin-top: 1rem;
	}
}
</style>
