<template>
	<div>
		<!-- Sending SMS Code -->
		<div v-if="!isVerificationVisible">
			<VuePhoneNumberInput
			class="input-number mt-4 mb-4"
			:default-country-code="'NO'"
			:preferred-countries="['NO', 'US', 'GB']"
			@update="onUpdate"
			v-model="phone" />
			<span class="text-danger text-sm">{{ errors.first('phone') }}</span>
			<div class="flex flex-wrap justify-between">
				<div class="mt-2">
					<vs-button
						:class="{ 'is-loading': isSendingSMSCode }"
						:disabled="!validateForm"
						@click="sendSmsCode">{{ $t('Send SMS Code') }}</vs-button>
				</div>
				<div class="flex flex-wrap justify-between my-5">
					<router-link to="/forgot-password">{{ $t('Forgot Password') }}</router-link>
				</div>
			</div>
		</div>

		<!-- SMS Verification -->
		<div v-if="isVerificationVisible">
			<vs-input v-filter-input
				v-validate="'required'"
				data-vv-validate-on="blur"
				name="code"
				icon-no-border
				icon="icon icon-code"
				icon-pack="feather"
				:label-placeholder="$t('Code')"
				v-model="code"
				class="w-full mt-8"
			/>
			<span class="text-danger text-sm">{{ errors.first('code') }}</span>

			<div class="mt-2">
				<vs-button
					:class="{ 'is-loading': isVerifying }"
					:disabled="!validateVerificationForm"
					@click="verifyCode">{{ $t('Login Verify') }}</vs-button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import authApi from '@/api/auth';
import { getAxiosErrorMessage } from '@/lib/helper';
import VuePhoneNumberInput from 'vue-phone-number-input';


export default {
  data() {
    return {
      phone: '',
      isSendingSMSCode: false,
      isVerificationVisible: false,
      code: '',
      isVerifying: false,
    };
  },
  components: {
    VuePhoneNumberInput,

  },
  computed: {
    ...mapGetters({
      loginError: 'loginError',
    }),
    validateForm() {
      return (
        !this.errors.any()
				&& this.phone !== ''
      );
    },
    validateVerificationForm() {
      return (
        !this.errors.any()
				&& this.code !== ''
      );
    },
  },
  methods: {
    // international input number
    onUpdate(payload) {
      this.resultsPhone = payload;
    },
    checkLogin() {
      // If user is already logged in notify
      // if (this.$store.state.auth.isUserLoggedIn()) {
      if (this.$store.state.isLoggedIn) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
        });

        return false;
      }
      return true;
    },
    sendSmsCode() {
      if (!this.checkLogin()) return;

      this.isSendingSMSCode = true;
      const params = {
        lang: this.lang,
        mobile_phone: this.resultsPhone.countryCallingCode + this.resultsPhone.nationalNumber,
      };
      const callback = (response) => {
        const message = response.message;
        this.$vs.notify({
          title: 'SMS Code',
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.isSendingSMSCode = false;
        this.isVerificationVisible = true;
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: 'SMS Code',
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isSendingSMSCode = false;
      };
      authApi.sendSmsCode(params, callback, errorCallback);
    },
    verifyCode() {
      if (!this.checkLogin()) return;

      this.isVerifying = true;
      const params = {
        lang: this.lang,
        mobile_phone: this.resultsPhone.countryCallingCode + this.resultsPhone.nationalNumber,
        code: this.code,
      };
      const callback = (response) => {
        this.$store.dispatch('loginAuth', response.data);
        const message = response.message;
        this.$vs.notify({
          title: 'SMS Code',
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.isVerifying = false;
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: 'SMS Code',
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isVerifying = false;
      };
      authApi.verifySmsCode(params, callback, errorCallback);
    },
    registerUser() {
      if (!this.checkLogin()) return;
      this.$router.push('/register').catch(() => {});
    },
    loginUrl() {
      if (!this.checkLogin()) return;
      this.$router.push('/login').catch(() => {});
    },
  },
  watch: {
    loginError(message) {
      if (message && message !== '') {
        this.$vs.notify({
          title: 'Login Error',
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      }
    },
  },
};
</script>
